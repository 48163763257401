import React from 'react'

const Banner = () => {
  return (
    <div>
        <div class="banner">
  <h2>Your Partner in Payroll and Compliance Excellence</h2>
  <p>Transforming Complex Processes into Seamless Solutions for Your Business Success</p>
</div>
    </div>
  )
}

export default Banner