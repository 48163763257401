import React from 'react'

const SubFooter = () => {
  return (
    <div>
        <div class="sub-footer">
  <p>Copyright © 2024 ComplyMaxManagement Pvt. Ltd. rights reserved</p>
</div>
    </div>
  )
}

export default SubFooter